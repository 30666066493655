// on first load
if ($(window).width() < 500) {
  //$("meta[name='viewport']").attr("content", "width=500");
  var dif = 500 - $(window).width(),
    one_percent = 500 / 100,
    percentage_to_change_by = dif / one_percent,
    percentage_to_decimal = percentage_to_change_by / 100,
    final_updated_viewport_scale = 1 - percentage_to_decimal;
  $("meta[name='viewport']").attr("content", "width=500, initial-scale=" + final_updated_viewport_scale.toFixed(2) + ", minimum-scale=" + final_updated_viewport_scale.toFixed(2));
}

// resize
var protection_width = $(window).width(),
  min_enforced_width_for_mobile = 500,
  update_viewport = function() {
    var triggered_resize_with = $(window).width();
    //console.log("triggered_resize_with: "+triggered_resize_with);
    if (protection_width !== triggered_resize_with && triggered_resize_with !== min_enforced_width_for_mobile) {
      protection_width = triggered_resize_with;

      if (triggered_resize_with < min_enforced_width_for_mobile) { // resize was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening

        var dif = min_enforced_width_for_mobile - protection_width,
          one_percent = min_enforced_width_for_mobile / 100,
          percentage_to_change_by = dif / one_percent,
          percentage_to_decimal = percentage_to_change_by / 100,
          final_updated_viewport_scale = 1 - percentage_to_decimal;

        $("meta[name='viewport']").attr("content", "width=500, initial-scale=" + final_updated_viewport_scale.toFixed(2) + ", minimum-scale=" + final_updated_viewport_scale.toFixed(2));
      } else {
        //alert(triggered_resize_with);
        $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=1");
      }
      $(window).off("resize.update_viewport");
      setTimeout(function() {
        $(window).on("resize.update_viewport", update_viewport);
      }, 400);
    }
  };
$(window).on("resize.update_viewport", update_viewport);
