// make plain text email address less likely to be spamed.
$(".less_spam_email").each(function() {
    var ats, dots, address, i;
    ats = [' at ', ' (at) ', ' [at] '];
    dots = [' dot ', ' (dot) ', ' [dot] ', ' [dot] '];
    address = $(this).html();
    for (i = 0; i < ats.length; i++) {
        address = address.replace(ats[i], '@');
    }
    for (i = 0; i < dots.length; i++) {
        address = address.replace(dots[i], '.');
    }
    $(this).html('<a target="_blank" href="mailto:' + address + '">' + address + '</a>');
});
